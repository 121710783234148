@import 'index';

.meta-items {
  display: inline-flex;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  @extend %copy-s;
  &.inherit-font {
    font-size: inherit;
    line-height: inherit;
  }
  .meta-item {
    display: flex;
    align-items: center;
    &:last-child::after {
      display: none;
    }
    &::after {
      display: inline-block;
      content: '';
      position: relative;
      height: 20px;
      width: 1px;
      background: $darkgrey1;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
