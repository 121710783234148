#uc-modal {
  .consent-label.cmm-checkbox.wb-type-label {
    align-items: flex-start;
    .cmm-checkbox-fake {
      margin-top: 0.15em;
    }
  }
  .uc-modal-content {
    border-radius: 0 !important;

    .uc-languages-list {
      display: none !important;
    }

    .main-title {
      font-size: 40px !important;
      line-height: 48px !important;

      @include media-breakpoint-down(sm) {
        font-size: 32px !important;
        line-height: 40px !important;
      }
    }

    .main-description {
      font-size: 18px !important;
      line-height: 27px !important;
    }

    .wb-button {
      font-size: 20px !important;
      border: none !important;
      border-radius: 0 !important;
      font-weight: normal !important;

      &:hover {
        color: white !important;
      }
    }

    .wb-link {
      font-size: 18px !important;
    }
  }
}

mb-dialog {
  .wb-button {
    border-radius: 20px;
  }
  cmm-cookie-banner {
    --wb-spacing-xs: 12px;
    --wb-spacing-m: 0;

    .consent-list {
      grid-template-columns: 1fr;
    }
  }
  .settings-page {
    padding-top: 0;

    .consent-list {
      column-count: 1;
      padding-left: 0;
      grid-template-columns: 1fr !important;
    }
    .consent-label {
      display: flex;
    }
    .consent-info-icon {
      margin-left: auto;
    }
  }
}
