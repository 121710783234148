@mixin mb_btn_mixin($colors) {
  color: map-get($colors, idle);
  background: map-get($colors, bg-idle);
  cursor: pointer;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: map-get($colors, hover);
      background: map-get($colors, bg-hover);
    }
  }

  &.active,
  &:active {
    color: map-get($colors, active);
    background: map-get($colors, bg-active);
  }
  &.disabled,
  &:disabled {
    pointer-events: none;
    color: map-get($colors, disabled);
    background: map-get($colors, bg-disabled);
  }
}
