@import 'index';

body {
  .cdk-overlay-dark-backdrop {
    background: none;
  }
  .mb-dialog-pane {
    max-width: initial !important;
    width: 100%;
    background: rgba(0, 0, 0, 0.87);

    .mat-mdc-dialog-container {
      --mdc-dialog-container-color: none;

      height: 100vh;
      width: 100vw;
      background: none;
      padding: 0;
    }
  }
}
