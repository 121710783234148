/* COLORS */
$blue1: #00adef; // MB Blue
$blue2: #0088c6;
$blue3: #006c9d;

$red: #9f0002;
$red1: #c80205;
$black: #000;
$black1: #0f0f0f;
$darkgrey8: #3d3d3d;
$darkgrey7: #4c4c4c;
$darkgrey6: #626262;
$darkgrey5: #999999;
$darkgrey4: #1f1f1f;
$darkgrey3: #333333;
$darkgrey2: #3c3c3c;
$darkgrey1: #666666;
$grey4: #dedede;
$grey3: #dddddd;
$grey2: #ececec;
$grey1: #f8f8f8;
$white: #ffffff;
$green: #24b800;
$green1: #28a745;
$green2: #008a00;
$orange: #ffbf00;

$primary: $blue1 !default;
$secondary: $black !default;
$success: $green2 !default;
$info: $black !default;
$warning: $orange !default;
$danger: $red !default;

$theme-colors: (
  'danger': $danger,
  'info': $info,
  'warning': $warning,
  'secondary': $secondary,
  'primary': $primary,
  'success': $success,
) !default;

$btn-primary-colors: (
  idle: $white,
  hover: $white,
  active: $white,
  disabled: $white,
  bg-idle: $blue1,
  bg-hover: $blue2,
  bg-active: $blue3,
  bg-disabled: $grey3,
);

$btn-secondary-colors: (
  idle: $white,
  hover: $white,
  active: $white,
  disabled: $white,
  bg-idle: $black,
  bg-hover: $darkgrey3,
  bg-active: $darkgrey1,
  bg-disabled: $grey3,
);

$btn-secondary-dark-colors: (
  idle: $white,
  hover: $white,
  active: $white,
  disabled: $darkgrey1,
  bg-idle: $darkgrey2,
  bg-hover: $darkgrey3,
  bg-active: $darkgrey4,
  bg-disabled: $darkgrey3,
);

$btn-tertiary-colors: (
  idle: $black,
  hover: $black,
  active: $black,
  disabled: $white,
  bg-idle: $grey2,
  bg-hover: $grey3,
  bg-active: $darkgrey5,
  bg-disabled: $grey3,
);

$btn-quaternary-colors: (
  idle: inherit,
  hover: $blue1,
  active: $blue2,
  disabled: $darkgrey1,
  bg-idle: none,
  bg-hover: none,
  bg-active: none,
  bg-disabled: none,
);
