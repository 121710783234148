@import 'index';

.bg-dark {
  background: $black;
  color: $white;
}

.bg-dark-2 {
  background: $darkgrey3;
  color: $white;
}

.bg-light {
  background: $white;
  color: $black;
}

.bg-grey {
  background: $grey4;
  color: $black;
}
