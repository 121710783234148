// boostrap config
$grid-breakpoints: (
  xxxs: 0,
  xxs: 375px,
  xs: 568px,
  sm: 768px,
  md: 1024px,
  lg: 1366px,
  xl: 1600px,
  xxl: 1920px,
);

$container-max-widths: (
  xxxs: 93.75%,
  xxs: 93.75%,
  xs: 93.75%,
  sm: 93.75%,
  md: 93.75%,
  lg: 93.75%,
  xl: 93.75%,
  xxl: 1800px,
);
