%title {
  font-size: 40px;
  line-height: 44px;

  @include media-breakpoint-up(sm) {
    font-size: 56px;
    line-height: 62px;
  }
}

%h1 {
  font-size: 36px;
  line-height: 40px;

  @include media-breakpoint-up(sm) {
    font-size: 48px;
    line-height: 53px;
  }
}

%h2 {
  font-size: 32px;
  line-height: 35px;

  @include media-breakpoint-up(sm) {
    font-size: 40px;
    line-height: 44px;
  }
}

%h3 {
  font-size: 28px;
  line-height: 31px;

  @include media-breakpoint-up(sm) {
    font-size: 32px;
    line-height: 35px;
  }
}

%h4 {
  font-size: 24px;
  line-height: 28px;
}

%h5,
%h6 {
  font-size: 28px;
  line-height: 31px;

  @include media-breakpoint-up(sm) {
    font-size: 24px;
    line-height: 26px;
  }
}

%intro {
  font-size: 20px;
  line-height: 30px;

  @include media-breakpoint-up(sm) {
    font-size: 24px;
    line-height: 36px;
  }
}

%copy-xl {
  font-size: 18px;
  line-height: 27px;

  @include media-breakpoint-up(sm) {
    font-size: 20px;
    line-height: 30px;
  }
}
%copy-l {
  font-size: 16px;
  line-height: 24px;
  @include media-breakpoint-up(sm) {
    font-size: 18px;
    line-height: 28px;
  }
}

%copy-m {
  font-size: 16px;
  line-height: 18px;
  @include media-breakpoint-up(sm) {
    line-height: 23px;
  }
}

%copy-s {
  font-size: 14px;
  line-height: 16px;
}

%button-label {
  font-size: 18px;
  line-height: 20px;
}
