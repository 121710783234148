@import 'index';

.mb-badge,
.mb-badge-small,
.mb-badge-xxs {
  display: inline-block;
  text-align: center;
  background-color: white;
  color: black;
  font-family: $fontFamilyText;
  &.inverse {
    background-color: black;
    color: white;
  }
}
.mb-badge {
  height: 28px;
  min-width: 28px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 14px;
  font-size: 16px;
  line-height: 28px;
}
.mb-badge-small {
  height: 24px;
  min-width: 24px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 24px;
}

.mb-badge-xxs {
  height: 18px;
  min-width: 18px;
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 12px;
  font-size: 14px;
  line-height: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  .mb-badge,
  .mb-badge-small {
    top: -0.5em;
    position: relative;
  }
}
