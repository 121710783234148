@import 'index';

html {
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// HEADLINES
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $fontFamilyTitle;
  color: inherit;
  font-weight: normal;
}

.top-bar {
  &:before {
    content: '';
    display: block;
    position: relative;
    height: 2px;
    width: 60px;
    background: #fff;
    margin-bottom: 24px;
  }
  &.top-bar-dark {
    &:before {
      background: $black;
    }
  }
}

.title {
  @extend %title;
}

h1,
.h1 {
  @extend %h1;
}

h2,
.h2 {
  @extend %h2;
}

h3,
.h3 {
  @extend %h3;
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  @extend %h4;
}

.intro {
  @extend %intro;
}

.copy-xl {
  @extend %copy-xl;
}
.copy-l {
  @extend %copy-l;
}

.copy-m {
  @extend %copy-m;
}

.copy-s {
  @extend %copy-s;
}

.highlight-text {
  background: $blue1;
  font-weight: bold;
}

.font-family-text {
  font-family: $fontFamilyText;
}

.font-family-title {
  font-family: $fontFamilyTitle;
}

.text-uppercase {
  text-transform: uppercase;
}

.button-label {
  @extend %button-label;
}

// make sub- and superscripts act nicely within texts
sub,
sup {
  line-height: 0;
  font-size: 0.7em;
}
