/* You can add global styles to this file, and also import other style files */
@use '@workbench/core/dist/scss/_utility.scss' as utility;

@import 'scss/main';

// TODO: this "normalisation" clashes with workbench styles (and is bad practise)
// * {
//   margin: 0;
//   padding: 0;
//   box-sizing: border-box;
// }
// Custom fixes, clean these up in the component(s) later
figure {
  margin: 0;
}
ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

// bootstrap
@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/variables-dark';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/utilities';
@import '../node_modules/bootstrap/scss/utilities/api';

html {
  &.multiangle-open,
  &.transcript-open {
    position: relative;
    height: 100vh;

    @include media-breakpoint-down(sm) {
      body {
        overflow: hidden;
        position: fixed;
        width: 100%;
        height: 100%;
      }
    }
  }
}

body {
  height: auto;
  min-height: 100%;
  max-width: 100vw;
  margin: 0;
  font-family: $fontFamilyText;
  background: #000;
}

/* general CSS */
a {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

html {
  --mb-header-bar-height: 80px;
  @include utility.breakpoint-from(mq3) {
    --mb-header-bar-height: 96px;
  }

  --mb-main-navigation-height: 0px;
  @include utility.breakpoint-from(mq5) {
    --mb-main-navigation-height: 57px;
  }

  --mb-breadcrumbs-height: 50px;
  --mb-sticky-header-height: 50px;

  --mb-header-height: calc(
    var(--mb-header-bar-height) + var(--mb-main-navigation-height) + var(--mb-breadcrumbs-height)
  );
}
