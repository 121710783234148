$mb_displays: none, block, inline-block, flex, inline-flex;

@each $mb_display in $mb_displays {
  .d-#{$mb_display} {
    display: $mb_display !important;
  }
  @each $size, $max-width in $grid-breakpoints {
    @include media-breakpoint-up(#{$size}) {
      .d-#{$size}-#{$mb_display} {
        display: #{$mb_display} !important;
      }
    }
    @include media-breakpoint-down(#{$size}) {
      .d-down-#{$size}-#{$mb_display} {
        display: #{$mb_display} !important;
      }
    }
  }
}
