@font-face {
  font-display: block;
  font-family: 'MBCorpo A Title Cond';
  font-style: normal;
  font-weight: 400;
  src: url('^./assets/fonts/mb/title/MBCorpoATitleCond-Regular-Web.eot');
  src: url('^./assets/fonts/mb/title/MBCorpoATitleCond-Regular-Web.eot#iefix') format('embedded-opentype'),
    url('^./assets/fonts/mb/title/MBCorpoATitleCond-Regular-Web.woff2') format('woff2'),
    url('^./assets/fonts/mb/title/MBCorpoATitleCond-Regular-Web.woff') format('woff'),
    url('^./assets/fonts/mb/title/MBCorpoATitleCond-Regular-Web.svg') format('svg');
}

@font-face {
  font-display: block;
  font-family: 'MBCorpo S Text';
  font-style: normal;
  font-weight: 400;
  src: url('^./assets/fonts/mb/text/MBCorpoSText-Regular-Web.eot');
  src: url('^./assets/fonts/mb/text/MBCorpoSText-Regular-Web.eot#iefix') format('embedded-opentype'),
    url('^./assets/fonts/mb/text/MBCorpoSText-Regular-Web.woff2') format('woff2'),
    url('^./assets/fonts/mb/text/MBCorpoSText-Regular-Web.woff') format('woff'),
    url('^./assets/fonts/mb/text/MBCorpoSText-Regular-Web.svg') format('svg');
}

@font-face {
  font-display: block;
  font-family: 'MBCorpo S Text';
  font-style: normal;
  font-weight: 700;
  src: url('^./assets/fonts/mb/text/MBCorpoSText-Bold-Web.eot');
  src: url('^./assets/fonts/mb/text/MBCorpoSText-Bold-Web.eot#iefix') format('embedded-opentype'),
    url('^./assets/fonts/mb/text/MBCorpoSText-Bold-Web.woff2') format('woff2'),
    url('^./assets/fonts/mb/text/MBCorpoSText-Bold-Web.woff') format('woff'),
    url('^./assets/fonts/mb/text/MBCorpoSText-Bold-Web.svg') format('svg');
}

@font-face {
  font-display: block;
  font-family: 'MBCorpo S Text';
  font-style: normal;
  font-weight: 300;
  src: url('^./assets/fonts/mb/text/MBCorpoSText-Light-Web.eot');
  src: url('^./assets/fonts/mb/text/MBCorpoSText-Light-Web.eot#iefix') format('embedded-opentype'),
    url('^./assets/fonts/mb/text/MBCorpoSText-Light-Web.woff2') format('woff2'),
    url('^./assets/fonts/mb/text/MBCorpoSText-Light-Web.woff') format('woff'),
    url('^./assets/fonts/mb/text/MBCorpoSText-Light-Web.svg') format('svg');
}
