@font-face {
  font-family: 'Icon-font-Mercedes-Me-Media';
  src: url('^./assets/fonts/icons/Icon-font-Mercedes-Me-Media.woff?v4') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.mb-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Icon-font-Mercedes-Me-Media' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: super;
  font-size: 1.2em;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mb-icon {
  &-xxl {
    font-size: 3em;
  }
  &-xl {
    font-size: 2em;
  }
  &-l {
    font-size: 1.5em;
  }
  &-m {
    font-size: 1em;
  }
  &-s {
    font-size: 0.8em;
  }
  &-xs {
    font-size: 0.6em;
  }
}

.mb-icon-service-flat {
  &:before {
    content: $mb-icon-service-flat;
  }
}
.mb-icon-service {
  &:before {
    content: $mb-icon-service;
  }
}
.mb-icon-article-thin {
  &:before {
    content: $mb-icon-article-thin;
  }
}
.mb-icon-article {
  &:before {
    content: $mb-icon-article;
  }
}
.mb-icon-attention-circle {
  &:before {
    content: $mb-icon-attention-circle;
  }
}
.mb-icon-music-flat {
  &:before {
    content: $mb-icon-music-flat;
  }
}
.mb-icon-audio-thin {
  &:before {
    content: $mb-icon-audio-thin;
  }
}
.mb-icon-audio {
  &:before {
    content: $mb-icon-audio;
  }
}
.mb-icon-music {
  &:before {
    content: $mb-icon-music;
  }
}
.mb-icon-calculator-flat {
  &:before {
    content: $mb-icon-calculator-flat;
  }
}
.mb-icon-bed {
  &:before {
    content: $mb-icon-bed;
  }
}
.mb-icon-bell {
  &:before {
    content: $mb-icon-bell;
  }
}
.mb-icon-calculator {
  &:before {
    content: $mb-icon-calculator;
  }
}
.mb-icon-mileage-flat {
  &:before {
    content: $mb-icon-mileage-flat;
  }
}
.mb-icon-bookmark-list {
  &:before {
    content: $mb-icon-bookmark-list;
  }
}
.mb-icon-mileage {
  &:before {
    content: $mb-icon-mileage;
  }
}
.mb-icon-light-dim-flat {
  &:before {
    content: $mb-icon-light-dim-flat;
  }
}
.mb-icon-chain {
  &:before {
    content: $mb-icon-chain;
  }
}
.mb-icon-clock-time {
  &:before {
    content: $mb-icon-clock-time;
  }
}
.mb-icon-light-dim {
  &:before {
    content: $mb-icon-light-dim;
  }
}
.mb-icon-light-bright-flat {
  &:before {
    content: $mb-icon-light-bright-flat;
  }
}
.mb-icon-delete-thin {
  &:before {
    content: $mb-icon-delete-thin;
  }
}
.mb-icon-light-bright {
  &:before {
    content: $mb-icon-light-bright;
  }
}
.mb-icon-documents-detail {
  &:before {
    content: $mb-icon-documents-detail;
  }
}
.mb-icon-documents {
  &:before {
    content: $mb-icon-documents;
  }
}
.mb-icon-pin-hotspot {
  &:before {
    content: $mb-icon-pin-hotspot;
  }
}
.mb-icon-ranking-flat {
  &:before {
    content: $mb-icon-ranking-flat;
  }
}
.mb-icon-edit-change {
  &:before {
    content: $mb-icon-edit-change;
  }
}
.mb-icon-eventslider-toogle-list {
  &:before {
    content: $mb-icon-eventslider-toogle-list;
  }
}
.mb-icon-eventslider-toogle-tiles {
  &:before {
    content: $mb-icon-eventslider-toogle-tiles;
  }
}
.mb-icon-facebook {
  &:before {
    content: $mb-icon-facebook;
  }
}
.mb-icon-favorites-press-map {
  &:before {
    content: $mb-icon-favorites-press-map;
  }
}
.mb-icon-filter-flat {
  &:before {
    content: $mb-icon-filter-flat;
  }
}
.mb-icon-filter {
  &:before {
    content: $mb-icon-filter;
  }
}
.mb-icon-ranking {
  &:before {
    content: $mb-icon-ranking;
  }
}
.mb-icon-save-flat {
  &:before {
    content: $mb-icon-save-flat;
  }
}
.mb-icon-contact-person {
  &:before {
    content: $mb-icon-contact-person;
  }
}
.mb-icon-save {
  &:before {
    content: $mb-icon-save;
  }
}
.mb-icon-edit-flat {
  &:before {
    content: $mb-icon-edit-flat;
  }
}
.mb-icon-info-circle {
  &:before {
    content: $mb-icon-info-circle;
  }
}
.mb-icon-edit {
  &:before {
    content: $mb-icon-edit;
  }
}
.mb-icon-design-flat {
  &:before {
    content: $mb-icon-design-flat;
  }
}
.mb-icon-legal-info {
  &:before {
    content: $mb-icon-legal-info;
  }
}
.mb-icon-linkedin {
  &:before {
    content: $mb-icon-linkedin;
  }
}
.mb-icon-design {
  &:before {
    content: $mb-icon-design;
  }
}
.mb-icon-call-flat {
  &:before {
    content: $mb-icon-call-flat;
  }
}
.mb-icon-magazine-toogle-list-text {
  &:before {
    content: $mb-icon-magazine-toogle-list-text;
  }
}
.mb-icon-magazine-toogle-list-thumbs {
  &:before {
    content: $mb-icon-magazine-toogle-list-thumbs;
  }
}
.mb-icon-magazine-toogle-tiles {
  &:before {
    content: $mb-icon-magazine-toogle-tiles;
  }
}
.mb-icon-call {
  &:before {
    content: $mb-icon-call;
  }
}
.mb-icon-archive-flat {
  &:before {
    content: $mb-icon-archive-flat;
  }
}
.mb-icon-archive {
  &:before {
    content: $mb-icon-archive;
  }
}
.mb-icon-home-flat {
  &:before {
    content: $mb-icon-home-flat;
  }
}
.mb-icon-menu {
  &:before {
    content: $mb-icon-menu;
  }
}
.mb-icon-home-line {
  &:before {
    content: $mb-icon-home-line;
  }
}
.mb-icon-picture-thin {
  &:before {
    content: $mb-icon-picture-thin;
  }
}
.mb-icon-picture {
  &:before {
    content: $mb-icon-picture;
  }
}
.mb-icon-player-pause {
  &:before {
    content: $mb-icon-player-pause;
  }
}
.mb-icon-player-play {
  &:before {
    content: $mb-icon-player-play;
  }
}
.mb-icon-360-degree-view-flat {
  &:before {
    content: $mb-icon-360-degree-view-flat;
  }
}
.mb-icon-player-state-no-thumbs-3-ansichten {
  &:before {
    content: $mb-icon-player-state-no-thumbs-3-ansichten;
  }
}
.mb-icon-player-state-no-thumbs {
  &:before {
    content: $mb-icon-player-state-no-thumbs;
  }
}
.mb-icon-menue-content {
  &:before {
    content: $mb-icon-menue-content;
  }
}
.mb-icon-player-state-script-3-ansichten {
  &:before {
    content: $mb-icon-player-state-script-3-ansichten;
  }
}
.mb-icon-player-state-script {
  &:before {
    content: $mb-icon-player-state-script;
  }
}
.mb-icon-player-state-split-2-ansichten {
  &:before {
    content: $mb-icon-player-state-split-2-ansichten;
  }
}
.mb-icon-player-state-split {
  &:before {
    content: $mb-icon-player-state-split;
  }
}
.mb-icon-player-state-thumbs-2-ansichten {
  &:before {
    content: $mb-icon-player-state-thumbs-2-ansichten;
  }
}
.mb-icon-player-state-thumbs-3-ansichten {
  &:before {
    content: $mb-icon-player-state-thumbs-3-ansichten;
  }
}
.mb-icon-player-state-thumbs {
  &:before {
    content: $mb-icon-player-state-thumbs;
  }
}
.mb-icon-player-transcript-thumbs {
  &:before {
    content: $mb-icon-player-transcript-thumbs;
  }
}
.mb-icon-player-view-no-thumbs {
  &:before {
    content: $mb-icon-player-view-no-thumbs;
  }
}
.mb-icon-player-view-split {
  &:before {
    content: $mb-icon-player-view-split;
  }
}
.mb-icon-player-view-thumbs {
  &:before {
    content: $mb-icon-player-view-thumbs;
  }
}
.mb-icon-print {
  &:before {
    content: $mb-icon-print;
  }
}
.mb-icon-360-degree-view-line {
  &:before {
    content: $mb-icon-360-degree-view-line;
  }
}
.mb-icon-consumption-flat {
  &:before {
    content: $mb-icon-consumption-flat;
  }
}
.mb-icon-profile-user-add {
  &:before {
    content: $mb-icon-profile-user-add;
  }
}
.mb-icon-profile-user {
  &:before {
    content: $mb-icon-profile-user;
  }
}
.mb-icon-resync {
  &:before {
    content: $mb-icon-resync;
  }
}
.mb-icon-rewatch {
  &:before {
    content: $mb-icon-rewatch;
  }
}
.mb-icon-schedule {
  &:before {
    content: $mb-icon-schedule;
  }
}
.mb-icon-consumption-line {
  &:before {
    content: $mb-icon-consumption-line;
  }
}
.mb-icon-shopping-cart-flat {
  &:before {
    content: $mb-icon-shopping-cart-flat;
  }
}
.mb-icon-tag {
  &:before {
    content: $mb-icon-tag;
  }
}
.mb-icon-textsnippet-thin {
  &:before {
    content: $mb-icon-textsnippet-thin;
  }
}
.mb-icon-textsnippet {
  &:before {
    content: $mb-icon-textsnippet;
  }
}
.mb-icon-transkript-error {
  &:before {
    content: $mb-icon-transkript-error;
  }
}
.mb-icon-twitter {
  &:before {
    content: $mb-icon-twitter;
  }
}
.mb-icon-facebook-round {
  &:before {
    content: $mb-icon-facebook-round;
  }
}
.mb-icon-instagram {
  &:before {
    content: $mb-icon-instagram;
  }
}
.mb-icon-youtube {
  &:before {
    content: $mb-icon-youtube;
  }
}
.mb-icon-shopping-cart {
  &:before {
    content: $mb-icon-shopping-cart;
  }
}
.mb-icon-back {
  &:before {
    content: $mb-icon-back;
  }
}
.mb-icon-video-camera-error {
  &:before {
    content: $mb-icon-video-camera-error;
  }
}
.mb-icon-video-movie-thin {
  &:before {
    content: $mb-icon-video-movie-thin;
  }
}
.mb-icon-back-thin {
  &:before {
    content: $mb-icon-back-thin;
  }
}
.mb-icon-up {
  &:before {
    content: $mb-icon-up;
  }
}
.mb-icon-up-thin {
  &:before {
    content: $mb-icon-up-thin;
  }
}
.mb-icon-wechat {
  &:before {
    content: $mb-icon-wechat;
  }
}
.mb-icon-whatsapp {
  &:before {
    content: $mb-icon-whatsapp;
  }
}
.mb-icon-zip {
  &:before {
    content: $mb-icon-zip;
  }
}
.mb-icon-forward {
  &:before {
    content: $mb-icon-forward;
  }
}
.mb-icon-forward-thin {
  &:before {
    content: $mb-icon-forward-thin;
  }
}
.mb-icon-down {
  &:before {
    content: $mb-icon-down;
  }
}
.mb-icon-event-invited {
  &:before {
    content: $mb-icon-event-invited;
  }
}
.mb-icon-event-declined {
  &:before {
    content: $mb-icon-event-declined;
  }
}
.mb-icon-event-accepted {
  &:before {
    content: $mb-icon-event-accepted;
  }
}
.mb-icon-quotation-mark {
  &:before {
    content: $mb-icon-quotation-mark;
  }
}
.mb-icon-picture-in-picture {
  &:before {
    content: $mb-icon-picture-in-picture;
  }
}
.mb-icon-down-thin {
  &:before {
    content: $mb-icon-down-thin;
  }
}
.mb-icon-double-arrow-back {
  &:before {
    content: $mb-icon-double-arrow-back;
  }
}
.mb-icon-double-arrow-forward {
  &:before {
    content: $mb-icon-double-arrow-forward;
  }
}
.mb-icon-media-back {
  &:before {
    content: $mb-icon-media-back;
  }
}
.mb-icon-media-forward {
  &:before {
    content: $mb-icon-media-forward;
  }
}
.mb-icon-media-prev {
  &:before {
    content: $mb-icon-media-prev;
  }
}
.mb-icon-media-next {
  &:before {
    content: $mb-icon-media-next;
  }
}
.mb-icon-bookmark-flat {
  &:before {
    content: $mb-icon-bookmark-flat;
  }
}
.mb-icon-bookmark {
  &:before {
    content: $mb-icon-bookmark;
  }
}
.mb-icon-calendar-date-add {
  &:before {
    content: $mb-icon-calendar-date-add;
  }
}
.mb-icon-calendar-date-flat {
  &:before {
    content: $mb-icon-calendar-date-flat;
  }
}
.mb-icon-calendar-date {
  &:before {
    content: $mb-icon-calendar-date;
  }
}
.mb-icon-mb-icon-time-flat {
  &:before {
    content: $mb-icon-mb-icon-time-flat;
  }
}
.mb-icon-time {
  &:before {
    content: $mb-icon-time;
  }
}
.mb-icon-add-circle {
  &:before {
    content: $mb-icon-add-circle;
  }
}
.mb-icon-remove-circle {
  &:before {
    content: $mb-icon-remove-circle;
  }
}
.mb-icon-add {
  &:before {
    content: $mb-icon-add;
  }
}
.mb-icon-close-small {
  &:before {
    content: $mb-icon-close-small;
  }
}
.mb-icon-close-thin {
  &:before {
    content: $mb-icon-close-thin;
  }
}
.mb-icon-close {
  &:before {
    content: $mb-icon-close;
  }
}
.mb-icon-contact-flat {
  &:before {
    content: $mb-icon-contact-flat;
  }
}
.mb-icon-contact {
  &:before {
    content: $mb-icon-contact;
  }
}
.mb-icon-delete {
  &:before {
    content: $mb-icon-delete;
  }
}
.mb-icon-attention {
  &:before {
    content: $mb-icon-attention;
  }
}
.mb-icon-help {
  &:before {
    content: $mb-icon-help;
  }
}
.mb-icon-information-credits {
  &:before {
    content: $mb-icon-information-credits;
  }
}
.mb-icon-language-country-switch-flat {
  &:before {
    content: $mb-icon-language-country-switch-flat;
  }
}
.mb-icon-language-country-switch {
  &:before {
    content: $mb-icon-language-country-switch;
  }
}
.mb-icon-step-back {
  &:before {
    content: $mb-icon-step-back;
  }
}
.mb-icon-download {
  &:before {
    content: $mb-icon-download;
  }
}
.mb-icon-upload {
  &:before {
    content: $mb-icon-upload;
  }
}
.mb-icon-log-out {
  &:before {
    content: $mb-icon-log-out;
  }
}
.mb-icon-login {
  &:before {
    content: $mb-icon-login;
  }
}
.mb-icon-link-out {
  &:before {
    content: $mb-icon-link-out;
  }
}
.mb-icon-rotate-portrait {
  &:before {
    content: $mb-icon-rotate-portrait;
  }
}
.mb-icon-rotate-landscape {
  &:before {
    content: $mb-icon-rotate-landscape;
  }
}
.mb-icon-marked-ok-thin {
  &:before {
    content: $mb-icon-marked-ok-thin;
  }
}
.mb-icon-marked-ok {
  &:before {
    content: $mb-icon-marked-ok;
  }
}
.mb-icon-statistics-reports-flat {
  &:before {
    content: $mb-icon-statistics-reports-flat;
  }
}
.mb-icon-cogs {
  &:before {
    content: $mb-icon-cogs;
  }
}
.mb-icon-statistics-reports {
  &:before {
    content: $mb-icon-statistics-reports;
  }
}
.mb-icon-image-gallery-flat {
  &:before {
    content: $mb-icon-image-gallery-flat;
  }
}
.mb-icon-image-gallery {
  &:before {
    content: $mb-icon-image-gallery;
  }
}
.mb-icon-player-stop {
  &:before {
    content: $mb-icon-player-stop;
  }
}
.mb-icon-chat-flat {
  &:before {
    content: $mb-icon-chat-flat;
  }
}
.mb-icon-chat-line {
  &:before {
    content: $mb-icon-chat-line;
  }
}
.mb-icon-comment-flat {
  &:before {
    content: $mb-icon-comment-flat;
  }
}
.mb-icon-comment-line {
  &:before {
    content: $mb-icon-comment-line;
  }
}
.mb-icon-pin-default {
  &:before {
    content: $mb-icon-pin-default;
  }
}
.mb-icon-pin-mb-location {
  &:before {
    content: $mb-icon-pin-mb-location;
  }
}
.mb-icon-map-dealer-pin-fill {
  &:before {
    content: $mb-icon-map-dealer-pin-fill;
  }
}
.mb-icon-map-dealer-pin-line {
  &:before {
    content: $mb-icon-map-dealer-pin-line;
  }
}
.mb-icon-print-flat {
  &:before {
    content: $mb-icon-print-flat;
  }
}
.mb-icon-print1 {
  &:before {
    content: $mb-icon-print1;
  }
}
.mb-icon-download-print-form-flat {
  &:before {
    content: $mb-icon-download-print-form-flat;
  }
}
.mb-icon-download-print-form {
  &:before {
    content: $mb-icon-download-print-form;
  }
}
.mb-icon-printed-version-form-flat {
  &:before {
    content: $mb-icon-printed-version-form-flat;
  }
}
.mb-icon-printed-version-form {
  &:before {
    content: $mb-icon-printed-version-form;
  }
}
.mb-icon-reload {
  &:before {
    content: $mb-icon-reload;
  }
}
.mb-icon-settings-flat {
  &:before {
    content: $mb-icon-settings-flat;
  }
}
.mb-icon-settings {
  &:before {
    content: $mb-icon-settings;
  }
}
.mb-icon-sharing {
  &:before {
    content: $mb-icon-sharing;
  }
}
.mb-icon-list {
  &:before {
    content: $mb-icon-list;
  }
}
.mb-icon-menue-content1 {
  &:before {
    content: $mb-icon-menue-content1;
  }
}
.mb-icon-menue-line {
  &:before {
    content: $mb-icon-menue-line;
  }
}
.mb-icon-media-full-screen-view-xs {
  &:before {
    content: $mb-icon-media-full-screen-view-xs;
  }
}
.mb-icon-media-full-screen-xs {
  &:before {
    content: $mb-icon-media-full-screen-xs;
  }
}
.mb-icon-grid-03 {
  &:before {
    content: $mb-icon-grid-03;
  }
}
.mb-icon-grid-02 {
  &:before {
    content: $mb-icon-grid-02;
  }
}
.mb-icon-grid-01 {
  &:before {
    content: $mb-icon-grid-01;
  }
}
.mb-icon-video-movie-flat {
  &:before {
    content: $mb-icon-video-movie-flat;
  }
}
.mb-icon-video-movie {
  &:before {
    content: $mb-icon-video-movie;
  }
}
.mb-icon-switch {
  &:before {
    content: $mb-icon-switch;
  }
}
.mb-icon-sound-high {
  &:before {
    content: $mb-icon-sound-high;
  }
}
.mb-icon-sound-low {
  &:before {
    content: $mb-icon-sound-low;
  }
}
.mb-icon-sound-mute {
  &:before {
    content: $mb-icon-sound-mute;
  }
}
.mb-icon-zoom-out {
  &:before {
    content: $mb-icon-zoom-out;
  }
}
.mb-icon-zoom-in {
  &:before {
    content: $mb-icon-zoom-in;
  }
}
.mb-icon-search-flat {
  &:before {
    content: $mb-icon-search-flat;
  }
}
.mb-icon-search {
  &:before {
    content: $mb-icon-search;
  }
}
.mb-icon-profile-users {
  &:before {
    content: $mb-icon-profile-users;
  }
}
.mb-icon-profile-users-flat {
  &:before {
    content: $mb-icon-profile-users-flat;
  }
}
.mb-icon-profile-user-flat {
  &:before {
    content: $mb-icon-profile-user-flat;
  }
}
.mb-icon-pin-kickoff {
  &:before {
    content: $mb-icon-pin-kickoff;
  }
}
.mb-icon-car-front-flat {
  &:before {
    content: $mb-icon-car-front-flat;
  }
}
.mb-icon-car-front {
  &:before {
    content: $mb-icon-car-front;
  }
}
.mb-icon-car-compare-flat {
  &:before {
    content: $mb-icon-car-compare-flat;
  }
}
.mb-icon-car-compare {
  &:before {
    content: $mb-icon-car-compare;
  }
}
.mb-icon-car-open-top-flat {
  &:before {
    content: $mb-icon-car-open-top-flat;
  }
}
.mb-icon-car-open-top {
  &:before {
    content: $mb-icon-car-open-top;
  }
}
.mb-icon-equipment-line-flat {
  &:before {
    content: $mb-icon-equipment-line-flat;
  }
}
.mb-icon-equipment-line1 {
  &:before {
    content: $mb-icon-equipment-line1;
  }
}
.mb-icon-car-add-flat {
  &:before {
    content: $mb-icon-car-add-flat;
  }
}
.mb-icon-car-add {
  &:before {
    content: $mb-icon-car-add;
  }
}
.mb-icon-seat-flat {
  &:before {
    content: $mb-icon-seat-flat;
  }
}
.mb-icon-seat {
  &:before {
    content: $mb-icon-seat;
  }
}
.mb-icon-engine-flat {
  &:before {
    content: $mb-icon-engine-flat;
  }
}
.mb-icon-engine {
  &:before {
    content: $mb-icon-engine;
  }
}
.mb-icon-fuel-electric-standalone-flat {
  &:before {
    content: $mb-icon-fuel-electric-standalone-flat;
  }
}
.mb-icon-fuel-electric-standalone-line {
  &:before {
    content: $mb-icon-fuel-electric-standalone-line;
  }
}
.mb-icon-fuel-standalone-flat {
  &:before {
    content: $mb-icon-fuel-standalone-flat;
  }
}
.mb-icon-fuel-standalone-line {
  &:before {
    content: $mb-icon-fuel-standalone-line;
  }
}
.mb-icon-fuel-comparative-electric-flat {
  &:before {
    content: $mb-icon-fuel-comparative-electric-flat;
  }
}
.mb-icon-fuel-comparative-electric-line {
  &:before {
    content: $mb-icon-fuel-comparative-electric-line;
  }
}
.mb-icon-fuel-comparative-flat {
  &:before {
    content: $mb-icon-fuel-comparative-flat;
  }
}
.mb-icon-fuel-comparative-line {
  &:before {
    content: $mb-icon-fuel-comparative-line;
  }
}
.mb-icon-equipment-flat {
  &:before {
    content: $mb-icon-equipment-flat;
  }
}
.mb-icon-equipment-line {
  &:before {
    content: $mb-icon-equipment-line;
  }
}
.mb-icon-test-drive-line {
  &:before {
    content: $mb-icon-test-drive-line;
  }
}
.mb-icon-picture-in-picture-exit {
  &:before {
    content: $mb-icon-picture-in-picture-exit;
  }
}
.mb-icon-test-card-passage {
  &:before {
    content: $mb-icon-test-card-passage;
  }
}
.mb-icon-test-card-landscape {
  &:before {
    content: $mb-icon-test-card-landscape;
  }
}
.mb-icon-test-card-curves {
  &:before {
    content: $mb-icon-test-card-curves;
  }
}
.mb-icon-test-card-highway {
  &:before {
    content: $mb-icon-test-card-highway;
  }
}
.mb-icon-test-card-freeway {
  &:before {
    content: $mb-icon-test-card-freeway;
  }
}
.mb-icon-pin-finalstation {
  &:before {
    content: $mb-icon-pin-finalstation;
  }
}
.mb-icon-travel-in {
  &:before {
    content: $mb-icon-travel-in;
  }
}
.mb-icon-travel-out {
  &:before {
    content: $mb-icon-travel-out;
  }
}
.mb-icon-racetrack {
  &:before {
    content: $mb-icon-racetrack;
  }
}
.mb-icon-train {
  &:before {
    content: $mb-icon-train;
  }
}
.mb-icon-travel-car {
  &:before {
    content: $mb-icon-travel-car;
  }
}
.mb-icon-airport {
  &:before {
    content: $mb-icon-airport;
  }
}
.mb-icon-plane-in {
  &:before {
    content: $mb-icon-plane-in;
  }
}
.mb-icon-plane-out {
  &:before {
    content: $mb-icon-plane-out;
  }
}
.mb-icon-coffeestop {
  &:before {
    content: $mb-icon-coffeestop;
  }
}
.mb-icon-restaurant {
  &:before {
    content: $mb-icon-restaurant;
  }
}
