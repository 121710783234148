@import 'index';

@import 'material';

@import 'typography/font';
@import 'typography/typography';
@import 'typography/icon';

@import 'ui/background';
@import 'ui/button';
@import 'ui/list';
@import 'ui/badge';
@import 'ui/dialog';
@import 'util/display';

@import 'components/user-centrics';

.maximize-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

// text elipsis style
.truncated-text {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

// util classes
@each $size, $max-width in $grid-breakpoints {
  @include media-breakpoint-down(#{$size}) {
    .hidden-#{$size} {
      display: none !important;
    }
  }
  @include media-breakpoint-up(#{$size}) {
    .hidden-#{$size}-up {
      display: none !important;
    }
  }
}

@keyframes slide-up-animation {
  from {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

[mbRevealWhenVisible] {
  opacity: 0;

  &.initial-reveal {
    opacity: 1;
  }

  &.slide-up-animation:not(.initial-reveal) {
    animation: slide-up-animation 0.5s forwards;
  }
}
