$icomoon-font-path: 'fonts' !default;

$mb-icon-service-flat: '\e900';
$mb-icon-service: '\e901';
$mb-icon-article-thin: '\e902';
$mb-icon-article: '\e903';
$mb-icon-attention-circle: '\e904';
$mb-icon-music-flat: '\e905';
$mb-icon-audio-thin: '\e906';
$mb-icon-audio: '\e907';
$mb-icon-music: '\e908';
$mb-icon-calculator-flat: '\e909';
$mb-icon-bed: '\e90a';
$mb-icon-bell: '\e90b';
$mb-icon-calculator: '\e90c';
$mb-icon-mileage-flat: '\e90d';
$mb-icon-bookmark-list: '\e90e';
$mb-icon-mileage: '\e90f';
$mb-icon-light-dim-flat: '\e910';
$mb-icon-chain: '\e911';
$mb-icon-clock-time: '\e912';
$mb-icon-light-dim: '\e913';
$mb-icon-light-bright-flat: '\e914';
$mb-icon-delete-thin: '\e915';
$mb-icon-light-bright: '\e916';
$mb-icon-documents-detail: '\e917';
$mb-icon-documents: '\e918';
$mb-icon-pin-hotspot: '\e919';
$mb-icon-ranking-flat: '\e91a';
$mb-icon-edit-change: '\e91b';
$mb-icon-eventslider-toogle-list: '\e91c';
$mb-icon-eventslider-toogle-tiles: '\e91d';
$mb-icon-facebook: '\e91e';
$mb-icon-favorites-press-map: '\e91f';
$mb-icon-filter-flat: '\e920';
$mb-icon-filter: '\e921';
$mb-icon-ranking: '\e922';
$mb-icon-save-flat: '\e923';
$mb-icon-contact-person: '\e924';
$mb-icon-save: '\e925';
$mb-icon-edit-flat: '\e926';
$mb-icon-info-circle: '\e927';
$mb-icon-edit: '\e928';
$mb-icon-design-flat: '\e929';
$mb-icon-legal-info: '\e92a';
$mb-icon-linkedin: '\e92b';
$mb-icon-design: '\e92c';
$mb-icon-call-flat: '\e92d';
$mb-icon-magazine-toogle-list-text: '\e92e';
$mb-icon-magazine-toogle-list-thumbs: '\e92f';
$mb-icon-magazine-toogle-tiles: '\e930';
$mb-icon-call: '\e931';
$mb-icon-archive-flat: '\e932';
$mb-icon-archive: '\e933';
$mb-icon-home-flat: '\e934';
$mb-icon-menu: '\e935';
$mb-icon-home-line: '\e936';
$mb-icon-picture-thin: '\e937';
$mb-icon-picture: '\e938';
$mb-icon-player-pause: '\e939';
$mb-icon-player-play: '\e93a';
$mb-icon-360-degree-view-flat: '\e93b';
$mb-icon-player-state-no-thumbs-3-ansichten: '\e93c';
$mb-icon-player-state-no-thumbs: '\e93d';
$mb-icon-menue-content: '\e93e';
$mb-icon-player-state-script-3-ansichten: '\e93f';
$mb-icon-player-state-script: '\e940';
$mb-icon-player-state-split-2-ansichten: '\e941';
$mb-icon-player-state-split: '\e942';
$mb-icon-player-state-thumbs-2-ansichten: '\e943';
$mb-icon-player-state-thumbs-3-ansichten: '\e944';
$mb-icon-player-state-thumbs: '\e945';
$mb-icon-player-transcript-thumbs: '\e946';
$mb-icon-player-view-no-thumbs: '\e947';
$mb-icon-player-view-split: '\e948';
$mb-icon-player-view-thumbs: '\e949';
$mb-icon-print: '\e94a';
$mb-icon-360-degree-view-line: '\e94b';
$mb-icon-consumption-flat: '\e94c';
$mb-icon-profile-user-add: '\e94d';
$mb-icon-profile-user: '\e94e';
$mb-icon-resync: '\e94f';
$mb-icon-rewatch: '\e950';
$mb-icon-schedule: '\e951';
$mb-icon-consumption-line: '\e952';
$mb-icon-shopping-cart-flat: '\e953';
$mb-icon-tag: '\e954';
$mb-icon-textsnippet-thin: '\e955';
$mb-icon-textsnippet: '\e956';
$mb-icon-transkript-error: '\e957';
$mb-icon-twitter: '\e958';
$mb-icon-facebook-round: '\e959';
$mb-icon-instagram: '\e95a';
$mb-icon-youtube: '\e95b';
$mb-icon-shopping-cart: '\e95c';
$mb-icon-back: '\e95d';
$mb-icon-video-camera-error: '\e95e';
$mb-icon-video-movie-thin: '\e95f';
$mb-icon-back-thin: '\e960';
$mb-icon-up: '\e961';
$mb-icon-up-thin: '\e962';
$mb-icon-wechat: '\e963';
$mb-icon-whatsapp: '\e964';
$mb-icon-zip: '\e965';
$mb-icon-forward: '\e966';
$mb-icon-forward-thin: '\e967';
$mb-icon-down: '\e968';
$mb-icon-event-invited: '\e969';
$mb-icon-event-declined: '\e96a';
$mb-icon-event-accepted: '\e96b';
$mb-icon-quotation-mark: '\e96c';
$mb-icon-picture-in-picture: '\e96d';
$mb-icon-down-thin: '\e96e';
$mb-icon-double-arrow-back: '\e96f';
$mb-icon-double-arrow-forward: '\e970';
$mb-icon-media-back: '\e971';
$mb-icon-media-forward: '\e972';
$mb-icon-media-prev: '\e973';
$mb-icon-media-next: '\e974';
$mb-icon-bookmark-flat: '\e975';
$mb-icon-bookmark: '\e976';
$mb-icon-calendar-date-add: '\e977';
$mb-icon-calendar-date-flat: '\e978';
$mb-icon-calendar-date: '\e979';
$mb-icon-mb-icon-time-flat: '\e97a';
$mb-icon-time: '\e97b';
$mb-icon-add-circle: '\e97c';
$mb-icon-remove-circle: '\e97d';
$mb-icon-add: '\e97e';
$mb-icon-close-small: '\e97f';
$mb-icon-close-thin: '\e980';
$mb-icon-close: '\e981';
$mb-icon-contact-flat: '\e982';
$mb-icon-contact: '\e983';
$mb-icon-delete: '\e984';
$mb-icon-attention: '\e985';
$mb-icon-help: '\e986';
$mb-icon-information-credits: '\e987';
$mb-icon-language-country-switch-flat: '\e988';
$mb-icon-language-country-switch: '\e989';
$mb-icon-step-back: '\e98a';
$mb-icon-download: '\e98b';
$mb-icon-upload: '\e98c';
$mb-icon-log-out: '\e98d';
$mb-icon-login: '\e98e';
$mb-icon-link-out: '\e98f';
$mb-icon-rotate-portrait: '\e990';
$mb-icon-rotate-landscape: '\e991';
$mb-icon-marked-ok-thin: '\e992';
$mb-icon-marked-ok: '\e993';
$mb-icon-statistics-reports-flat: '\e994';
$mb-icon-cogs: '\e995';
$mb-icon-statistics-reports: '\e996';
$mb-icon-image-gallery-flat: '\e997';
$mb-icon-image-gallery: '\e998';
$mb-icon-player-stop: '\e999';
$mb-icon-chat-flat: '\e99a';
$mb-icon-chat-line: '\e99b';
$mb-icon-comment-flat: '\e99c';
$mb-icon-comment-line: '\e99d';
$mb-icon-pin-default: '\e99e';
$mb-icon-pin-mb-location: '\e99f';
$mb-icon-map-dealer-pin-fill: '\e9a0';
$mb-icon-map-dealer-pin-line: '\e9a1';
$mb-icon-print-flat: '\e9a2';
$mb-icon-print1: '\e9a3';
$mb-icon-download-print-form-flat: '\e9a4';
$mb-icon-download-print-form: '\e9a5';
$mb-icon-printed-version-form-flat: '\e9a6';
$mb-icon-printed-version-form: '\e9a7';
$mb-icon-reload: '\e9a8';
$mb-icon-settings-flat: '\e9a9';
$mb-icon-settings: '\e9aa';
$mb-icon-sharing: '\e9ab';
$mb-icon-list: '\e9ac';
$mb-icon-menue-content1: '\e9ad';
$mb-icon-menue-line: '\e9ae';
$mb-icon-media-full-screen-view-xs: '\e9af';
$mb-icon-media-full-screen-xs: '\e9b0';
$mb-icon-grid-03: '\e9b1';
$mb-icon-grid-02: '\e9b2';
$mb-icon-grid-01: '\e9b3';
$mb-icon-video-movie-flat: '\e9b4';
$mb-icon-video-movie: '\e9b5';
$mb-icon-switch: '\e9b6';
$mb-icon-sound-high: '\e9b7';
$mb-icon-sound-low: '\e9b8';
$mb-icon-sound-mute: '\e9b9';
$mb-icon-zoom-out: '\e9ba';
$mb-icon-zoom-in: '\e9bb';
$mb-icon-search-flat: '\e9bc';
$mb-icon-search: '\e9bd';
$mb-icon-profile-users: '\e9be';
$mb-icon-profile-users-flat: '\e9bf';
$mb-icon-profile-user-flat: '\e9c0';
$mb-icon-pin-kickoff: '\e9c1';
$mb-icon-car-front-flat: '\e9c2';
$mb-icon-car-front: '\e9c3';
$mb-icon-car-compare-flat: '\e9c4';
$mb-icon-car-compare: '\e9c5';
$mb-icon-car-open-top-flat: '\e9c6';
$mb-icon-car-open-top: '\e9c7';
$mb-icon-equipment-line-flat: '\e9c8';
$mb-icon-equipment-line1: '\e9c9';
$mb-icon-car-add-flat: '\e9ca';
$mb-icon-car-add: '\e9cb';
$mb-icon-seat-flat: '\e9cc';
$mb-icon-seat: '\e9cd';
$mb-icon-engine-flat: '\e9ce';
$mb-icon-engine: '\e9cf';
$mb-icon-fuel-electric-standalone-flat: '\e9d0';
$mb-icon-fuel-electric-standalone-line: '\e9d1';
$mb-icon-fuel-standalone-flat: '\e9d2';
$mb-icon-fuel-standalone-line: '\e9d3';
$mb-icon-fuel-comparative-electric-flat: '\e9d4';
$mb-icon-fuel-comparative-electric-line: '\e9d5';
$mb-icon-fuel-comparative-flat: '\e9d6';
$mb-icon-fuel-comparative-line: '\e9d7';
$mb-icon-equipment-flat: '\e9d8';
$mb-icon-equipment-line: '\e9d9';
$mb-icon-test-drive-line: '\e9da';
$mb-icon-picture-in-picture-exit: '\e9db';
$mb-icon-test-card-passage: '\e9dc';
$mb-icon-test-card-landscape: '\e9dd';
$mb-icon-test-card-curves: '\e9de';
$mb-icon-test-card-highway: '\e9df';
$mb-icon-test-card-freeway: '\e9e0';
$mb-icon-pin-finalstation: '\e9e1';
$mb-icon-travel-in: '\e9e2';
$mb-icon-travel-out: '\e9e3';
$mb-icon-racetrack: '\e9e4';
$mb-icon-train: '\e9e5';
$mb-icon-travel-car: '\e9e6';
$mb-icon-airport: '\e9e7';
$mb-icon-plane-in: '\e9e8';
$mb-icon-plane-out: '\e9e9';
$mb-icon-coffeestop: '\e9ea';
$mb-icon-restaurant: '\e9eb';
