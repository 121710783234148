.btn-plain,
.btn {
  border: none;
  outline: none;
  box-shadow: none;
  display: inline-flex;
  text-decoration: none;
  font-family: $fontFamilyText;
  @extend %button-label;
}

.btn-plain {
  @include mb_btn_mixin($btn-quaternary-colors);
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  $button_animation_speed: 0.3s;
  height: 45px;
  padding: 12px 20px;
  transition: background-color $button_animation_speed, color $button_animation_speed;
  // we set the primary button as default
  .mb-icon {
    &:first-child {
      margin-right: 10px;
    }
    &:last-child {
      margin-left: 10px;
    }
    &:first-child:last-child {
      margin-right: 0;
      margin-left: 0;
    }
  }
  @include mb_btn_mixin($btn-primary-colors);

  &.btn-secondary {
    @include mb_btn_mixin($btn-secondary-colors);
    .bg-dark & {
      @include mb_btn_mixin($btn-secondary-dark-colors);
    }
  }
  &.btn-secondary-dark {
    @include mb_btn_mixin($btn-secondary-dark-colors);
  }
  &.btn-tertiary {
    @include mb_btn_mixin($btn-tertiary-colors);
  }
  &.btn-quaternary {
    @include mb_btn_mixin($btn-quaternary-colors);
  }
  &.btn-text {
    @include mb_btn_mixin($btn-quaternary-colors);
    text-decoration: underline;
    text-decoration-color: $blue1;
    text-underline-offset: 2px;
  }

  &.btn-plain {
    @include mb_btn_mixin($btn-quaternary-colors);
    padding: 0;
    height: auto;
  }

  &.btn-quaternary,
  &.btn-text {
    &.inline {
      display: inline;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.btn-round-l,
  &.btn-round {
    color: inherit;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $blue1;
    border-radius: 50%;
    height: 48px;
    width: 48px;
    font-size: 16px;
    background: transparent;
    transition: background-color $button_animation_speed, color $button_animation_speed,
      transform $button_animation_speed;
    &.active {
      &:not(:disabled),
      &:not(.disabled) {
        transform: scale(1.1);
        border-color: $blue1;
        color: $blue1;
      }
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        &:not(:disabled),
        &:not(.disabled) {
          transform: scale(1.1);
          border-color: $blue1;
          color: $blue1;
        }
      }
    }

    &.btn-round-l {
      @include media-breakpoint-up(xl) {
        height: 64px;
        width: 64px;
        font-size: 24px;
      }
    }
    &:disabled,
    &.disabled {
      pointer-events: none;
      border-color: $darkgrey1;
      color: $darkgrey1;
    }
    .mb-icon {
      margin: 0;
    }
  }
}

.btn-color {
  $button_animation_speed: 0.3s;
  transition: background-color $button_animation_speed, color $button_animation_speed;
  @include mb_btn_mixin($btn-primary-colors);

  &.btn-secondary {
    @include mb_btn_mixin($btn-secondary-colors);
    .bg-dark & {
      @include mb_btn_mixin($btn-secondary-dark-colors);
    }
  }
  &.btn-secondary-dark {
    @include mb_btn_mixin($btn-secondary-dark-colors);
  }
  &.btn-tertiary {
    @include mb_btn_mixin($btn-tertiary-colors);
  }
  &.btn-quaternary {
    @include mb_btn_mixin($btn-quaternary-colors);
  }
}
